import {
  UPDATE_EDITOR_NODE,
  UPDATE_EDITOR_STATE,
  UPDATE_TITLE_EDITOR_STATE,
} from '../actions/types';

export default function (state = [], action) {
  switch (action.type) {
    case UPDATE_EDITOR_NODE: {
      return {
        ...state,
        editorNode: action.payload,
      };
    }
    case UPDATE_EDITOR_STATE: {
      return {
        ...state,
        editorState: action.payload,
      };
    }
    case UPDATE_TITLE_EDITOR_STATE: {
      return {
        ...state,
        titleEditorState: action.payload,
      };
    }
    default:
      return state;
  }
}
